<template>
    <div >
        <div>
            <div class="topSearchBar">
                <el-row>
                    <el-col :xs="0" :sm="1" :md="4" :lg="10" :xl="10" style="height: 10px">
                    </el-col>
                    <el-col  :xs="24" :sm="23" :md="20" :lg="14" :xl="14">
                        <el-date-picker size="small" style="margin-right: 1vw"
                                        v-model="value1" @change="changeDate()"
                                        type="daterange"
                                        format="yyyy-MM-dd"
                                        :picker-options="pickerOptions"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                        </el-date-picker>
                        <el-select size="small" style="margin-right: 1.5vw"
                                   v-model="queryForm.factoryNo" @change="selectFactory()"
                                   clearable
                                   placeholder="请选择工厂">
                            <el-option
                                v-for="item in queryFactoryList"
                                :key="item.id" :label="item.factoryName" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select size="small" style="margin-right: 1.5vw"
                                   v-model="queryForm.subFactoryNo" @change="selectSubFactory()"
                                   clearable
                                   placeholder="请选择车间">
                            <el-option
                                v-for="item in querySubFactoryList"
                                :key="item.id" :label="item.factoryName" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-button size="mini" type="primary" @click="download()">下载数据</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="tabsBelowSearchBar">
                <el-row>
                    <el-col :span="24" style="margin-top:-88px">
                        <el-tabs v-model="activeName"  @tab-click="handleClick" style="padding-left: 10px">

                            <el-tab-pane label="曲线图" name="graph" >
                                <div ref="graph" style="width: 100%; height: 550px;margin-top:65px"></div>
                            </el-tab-pane>

                            <el-tab-pane label="柱状图" name="bar">
                                <div ref="bar" style="width: 100%; height: 550px;margin-top:65px"></div>
                            </el-tab-pane>

                            <el-tab-pane label="饼图" name="pie">
                                <div ref="pie" style="width: 100%; height: 550px;margin-top:65px"></div>
                            </el-tab-pane>

                            <el-tab-pane label="数据" name="data">
                                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;margin-top:40px" :height="tableHeight">
                                    <el-table-column prop="deviceNo" label="设备名" width="100"></el-table-column>
                                    <el-table-column prop="productCode" label="产品编码" width="130"></el-table-column>
                                    <el-table-column prop="datetime" label="测量时间" :formatter="dateFormat" width="200"></el-table-column>
                                    <el-table-column prop="type" label="类型" width="120"></el-table-column>
                                    <el-table-column prop="aArea" label="A面大小" width="150"></el-table-column>
                                    <el-table-column prop="bArea" label="B面大小" width="150"></el-table-column>
                                    <el-table-column prop="aIsOk" label="A面是否合格" width="150"></el-table-column>
                                    <el-table-column prop="bIsOk" label="B面是否合格" width="150"></el-table-column>
                                    <el-table-column prop="aImageName" label="A图名" width="220"></el-table-column>
                                    <el-table-column prop="bImageName" label="B图名" ></el-table-column>
                                </el-table>
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[20,50,100,200]"
                                    :page-size="20"
                                    layout="total,sizes,prev,pager,next,jumper"
                                    :total="Number(totalCount)">
                                </el-pagination>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>

        </div>
    </div>
</template>

<script>
import Index from '../../components/Index.vue';
import {fDateTimeFormat, fGetApiURL, getStyle,} from "@/utils/utilsset";
import {sanyouAxios} from "@/api";
export default {
    components: { Index },
    name: "hisdata",

    data() {
        return {
            currentUserId:"",
            //表格高度
            tableHeight:500,
            //表格数据
            tableData: [],
            //tabs
            activeName: "graph",
            value1:[],
            //分页
            currentPage:1,
            pageSize: 20,
            totalCount:0,
            //选择工厂
            queryFactoryList:[],
            //选择车间
            querySubFactoryList:[],
            queryForm:{
                factoryNo: "",
                subFactoryNo: "",
            },
            //日期
            startTime:"",
            endTime:"",
            //图表
            chartWidth:'',
            myGraph:'',
            myBar:'',
            myPie:'',
            //限制选择日期为当天之前
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() >= (Date.now()-8.64e7)
                }
            }
        };
    },
    mounted() {
        this.$nextTick(() => {
            //this.drawGraph();
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 300;
            window.onresize = () => {
                this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 300;
            };
        });
        this.chartWidth=getStyle(this.$refs.graph,'width');
        window.onresize=()=>{
            this.chartWidth=getStyle(this.$refs.graph,'width');
            this.resizeChart();
        }
    },
    methods: {
        dateFormat:function(row,column){
            let date = row[column.property];
            if(date === undefined){
                return ''
            }
            return fDateTimeFormat(date);//moment(date).format("YYYY-MM-DD HH:mm:ss")
        },
        //点击标签事件
        handleClick(tab, event) {
            //console.log(this.activeName);
            if (tab.name === "data") {
                this.showData();
            } else  {
                this.loadChart();
                //this.resizeChart();
            }
        },
        //用于echart的自动伸缩
        resizeChart(){
            this.myGraph.resize({
                width: this.chartWidth,
                height: 500,
            });
            this.myBar.resize({
                width: this.chartWidth,
                height: 500,
            });
            this.myPie.resize({
                width: this.chartWidth,
                height: 500,
            });
        },
        //加载Chart
        loadChart() {
            sanyouAxios({
                // headers:{
                //     userId:this.currentUserId
                // },
                method: 'POST',
                url: '/copper/copperHistoryData',
                data: {
                    startDate:this.startTime,
                    endDate:this.endTime,
                    subFactoryNo:this.queryForm.subFactoryNo,
                }
            }).then(res=>{
                if(res.data.status === 200){
                    let dataSet=[];
                    let xAxis = ['日期'];
                    let unqualifiedCopperNum = ['不合格'];
                    let qualifiedCopperNum = ['合格'];
                    for(let i=0;i<res.data.data.length;i++){
                        xAxis.push(res.data.data[i]['date']);
                        unqualifiedCopperNum.push(res.data.data[i]['unqualifiedCopperNum']);
                        qualifiedCopperNum.push(res.data.data[i]['qualifiedCopperNum']);
                    }
                    dataSet.push(xAxis);//表头数据
                    dataSet.push(qualifiedCopperNum);//合格
                    dataSet.push(unqualifiedCopperNum);//不合格
                    //console.log(dataSet);
                    this.drawGraph(dataSet);
                    this.drawBar(dataSet);
                    this.drawPie(dataSet);
                }else{
                    this.$message({
                        duration: 2000,
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            });
        },
        //曲线图
        drawGraph(dataSet) {
            this.myGraph = this.$echarts.init(this.$refs.graph);
            this.myGraph.setOption({
                legend: {},
                tooltip: {},
                dataset: {
                    // 提供一份数据。
                    source: dataSet,
                },
                // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
                xAxis: { type: 'category' },
                // 声明一个 Y 轴，数值轴。
                yAxis: {},
                // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
                series: [{ type: 'line', seriesLayoutBy: 'row',itemStyle: {color: "#4CAE85"},},
                    { type: 'line', seriesLayoutBy: 'row',itemStyle: {color: "#FF6356"},}, ]
            });
            this.myGraph.resize({
                width: this.chartWidth,
                height: 500,
            });
        },
        //柱状图
        drawBar(dataSet) {
            this.myBar = this.$echarts.init(this.$refs.bar);
            this.myBar.setOption({
                legend: {},
                tooltip: {},
                dataset: {
                    // 提供一份数据。
                    source: dataSet,
                },
                // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
                xAxis: { type: 'category' },
                // 声明一个 Y 轴，数值轴。
                yAxis: {},
                // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
                series: [{ type: 'bar', seriesLayoutBy: 'row',itemStyle: {color: "#4CAE85"},},
                    { type: 'bar', seriesLayoutBy: 'row',itemStyle: {color: "#FF6356"},}, ]
            });
            this.myBar.resize({
                width: this.chartWidth,
                height: 500,
            });
        },
        //饼状图
        drawPie(dataSet) {
            this.myPie = this.$echarts.init(this.$refs.pie);
            let unqualCopSum=0;
            let qualCopSum=0;
            //console.log(Number(dataSet[1][0]))
            for(let x in dataSet[1]){
                if(!isNaN(Number(dataSet[1][x])))
                    unqualCopSum+=Number(dataSet[2][x]);
            }
            for(let x in dataSet[2]){
                if(!isNaN(Number(dataSet[2][x])))
                    qualCopSum+=Number(dataSet[1][x]);
            }
            let totalSum=[/*['',''],*/['合格',qualCopSum,],['不合格', unqualCopSum]];
            //console.log(totalSum)
            this.myPie.setOption({
                title: {
                    text: "检测数据历史统计", //主标题
                    x: "center", //x轴方向对齐方式
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b}: {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    bottom: "bottom",
                },
                dataset: {
                    // 提供一份数据。
                    source: totalSum,
                },
                series: [
                    {
                        type: "pie",
                        radius: "80%",
                        center: ["50%", "50%"],
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                            normal: {
                                color: function(params) {
                                    //自定义颜色
                                    let colorList = [
                                        '#4CAE85','#FF6356', '#FFAD00', '#4CAE85'
                                    ];
                                    return colorList[params.dataIndex]
                                }
                            }
                        },
                    },
                ],
            });
            this.myPie.resize({
                width: this.chartWidth,
                height: 500,
            });
        },
        //显示数据
        showData() {
            this.loadData(1,20);
        },
        //加载检测数据
        loadData: function(page, pageSize){
            let _this = this;

            if(page == null || isNaN(page))
                page = 1;
            if(pageSize == null || isNaN(pageSize))
                pageSize = 20;

            sanyouAxios({
                /*
                headers:{
                    userId:this.currentUserId
                },*/
                method: 'POST',
                url:'/copper/copperHistoryList?page=' + page + '&size=' + pageSize+'&equipType=2',
                data:{
                    startDate:this.startTime,
                    endDate:this.endTime,
                    subFactoryNo:this.queryForm.subFactoryNo,
                }
            }).then(res => {
                if(res.data.status === 200){
                    let data = res.data.data;
                    _this.tableData = data.rows;
                    console.log(_this.tableData);
                    //undo:这里需要返回总条数
                    _this.totalCount = data.records;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: '没有权限查看!'
                    });
                }
            });
        },
        //每页显示数据量变更
        handleSizeChange: function (val) {
            this.pageSize = val;
            let currentPage = this.currentPage;
            this.loadData(currentPage,val);
        },
        //页码变更
        handleCurrentChange: function (val) {
            this.currentPage = val;
            let pageSize = this.pageSize;
            this.loadData(val,pageSize);
        },

        //获取用户所拥有权限的工厂信息
        loadFactoryList(){
            sanyouAxios({method: 'GET',
                url:"/factory/getFactory?userId=" + this.currentUserId})
                .then(res=>{
                    if(res.data.status === 200){
                        this.queryFactoryList = res.data.data;
                        this.queryForm.factoryNo = this.queryFactoryList[0].id;
                        this.loadSubFactoryList();
                        // this.loadChart();
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取车间信息失败!'
                        });
                    }
                });
        },

        loadSubFactoryList(){
            sanyouAxios({method: 'GET',
                url:"/factory/getSubFactory?userId=" + this.currentUserId + "&factoryId=" + this.queryForm.factoryNo})
                .then(res=>{
                    if(res.data.status === 200){
                        this.querySubFactoryList = res.data.data;
                        this.queryForm.subFactoryNo = this.querySubFactoryList[0].id;
                        this.loadChart();
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取车间信息失败!'
                        });
                    }
                });
        },

        //下载数据
        download(){
            console.log(fGetApiURL()+"/copper/downloadHistoryData?startDate=" + this.startTime +
                "&endDate=" + this.endTime + "&subFactoryNo=" + this.queryForm.subFactoryNo.id);
            window.open(fGetApiURL()+"/copper/downloadHistoryData?startDate=" + this.startTime +
            "&endDate=" + this.endTime + "&subFactoryNo=" + this.queryForm.subFactoryNo);
        },
        selectSubFactory(){
            if (this.activeName === "data") {
                this.loadData(1,20);
            }
            else {
                this.loadChart();
            }
        },
        selectFactory(){
            this.loadSubFactoryList();
        },
        changeDate(){
            if(this.value1 != null && this.value1.length > 0){
                this.startTime=this.value1[0];
                this.endTime=this.value1[1];
                if (this.activeName === "data") {
                    this.loadData(1,20);
                }
                else {
                    this.loadChart();
                }
            }
        }
    },

    created() {
        this.currentUserId = localStorage.getItem('id');

        this.loadFactoryList();

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        //let month = Number(date.getDate())!==1?date.getMonth():date.getMonth()-1;
        let day = date.getDate()-1;
        this.startTime = fDateTimeFormat(new Date(year,Number(date.getDate())!==1?month:month-1,1));
        this.endTime = fDateTimeFormat(new Date(year,month,day));
        this.value1.push(new Date(year,Number(date.getDate())!==1?month:month-1,1));
        this.value1.push(new Date(year,month,day));
    }
};
</script>
<style scoped>
.topSearchBar{
    width: 100%;
    /*margin-top: 60px;*/
    margin-top: 10px;
}
.tabsBelowSearchBar{
    width: 100%;
    /*margin-top: -80px;*/
    margin-top: 80px;
}
/*.btnSearch {
    margin: 5px 10px;
    float: right;
}*/
</style>
