import moment from "moment/moment";
import axios from "axios";
//************************数据字典相关begin***************************
//根据数据字典code返回值(建议使用下面封装好的   fGetDataInfoFormLS() )
export function getDataInfoDetails(axios, dataCode){
    return axios({
        /*headers:{
            userId:this.currentUserId
        },*/
        method: 'POST',
        url: '/dataInfo/getSimpleDataInfoDetails',
        params: {
            dataCode: dataCode,
        }
    })
}
//懒加载数据字典(登录时使用)
export async function fLoadCurDataInfo(axios,message){
    let dataInfoDetails=JSON.parse(localStorage.getItem('dataInfoDetails')||'[]');
    if(dataInfoDetails.length===0) {//如果localStorage内存在dataInfoDetails
        await fLoadAllDataInfo(axios, message);
    }
}
//加载数据字典(统一同步时使用)
export async function fLoadAllDataInfo(axios,message){
    //清空
    localStorage.setItem('dataInfoDetails', '');
    if(message){
        message({
            type: "info",
            duration: 2000,
            message: "正在拉取数据字典",
        });
    }

    let data=[];
    await axios({
        /*headers:{
            userId:this.currentUserId
        },*/
        method: 'POST',
        url:'/dataInfo/getAllDataInfos',
    }).then(res => {
        if(res.data.status === 200){
            data = res.data.data
            //console.log( res.data.data);//res.data.data

        }else{
            if(message){
                message({
                    type: 'warning',
                    duration: 2000,
                    message: '获取数据字典失败!'
                });
            }
        }
    });
    for(let i=0;i<data.length;i++){
        await fGetDataInfoByDataCode(axios,data[i]['dataCode'])
    }
    if(message){
        message({
            type: "success",
            duration: 2000,
            message: "获取数据字典成功!",
        });
    }
}
//数据字典的前端映射
export function defaultFilter(item){
    item.label=item.dataName;
    item.value=item.dataCode;
}
//根据dataCode返回数据字典
export async function fGetDataInfoByDataCode(axios,dataCode){
    //使用localStorage作为缓存
    //console.log(localStorage.getItem('dataInfoDetails'));
    let dataInfoDetails=JSON.parse(localStorage.getItem('dataInfoDetails')||'[]');
    let result;
    if(dataInfoDetails) {//如果localStorage内存在dataInfoDetails

        result=dataInfoDetails.find((item) => {  return String(item['dataCode']) === String(dataCode);  })
        if(result){
            //且dataInfoDetails内存在输入的dataCode
            return result.data;
        }
    }
    else{
        dataInfoDetails=[];
    }
    await getDataInfoDetails(axios,dataCode)
        .then(res => {
            if(res.data.status === 200){
                let data = res.data.data;
                data.forEach(defaultFilter);
                result=data;
                //console.log(data);
                dataInfoDetails.push({dataCode:dataCode,data:data})
                //console.log(dataInfoDetails);
                localStorage.setItem('dataInfoDetails', JSON.stringify(dataInfoDetails));
            }else{
                console.log('未能加载数据字典');
            }
        });
    return result;
}
//根据dataCode,从localstorage返回数据字典
export function fGetDataInfoFormLS(dataCode){//tableData：整个table的全部数据;  colName：数据的列名;  dataCode: 数据字典的编码
    let dataInfoDetails=JSON.parse(localStorage.getItem('dataInfoDetails')||'[]');
    return dataInfoDetails.find((item) => {  return String(item['dataCode']) === String(dataCode);  }) || [];
}
//table表格的数据数据渲染用()
export function fDataInfoFormatter(dataCode, row, colName){//tableData：整个table的全部数据;  colName：数据的列名;  dataCode: 数据字典的编码
    let res = fGetDataInfoFormLS(dataCode);
    if(res&&res.data){
        let oRes=res['data'].find((item) => {  return String(row[colName]) === String(item.dataCode);  })
        if(oRes)
            return oRes['label'];
    }
    //console.log(res);
    return row[colName]
}
//************************数据字典相关end***************************
//统一化时间
export function fDateFormat(date){
    return moment(date).format("YYYY-MM-DD")
}
//统一化时间
export function fDateTimeFormat(date){
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
}
//监听DOM元素高度和宽度
export function getStyle(ele,attr){
    if(window.getComputedStyle){
        return window.getComputedStyle(ele,null)[attr];
    }
    return ele.currentStyle[attr];
}
//统一api的ip和port
export function fGetIPPort(){
    let apiURL= axios.defaults.baseURL
    let url=apiURL.split('/')[2];
    return url.split(':');
}
//统一api的ip和port
export function fGetApiURL(){
    return axios.defaults.baseURL;
}
//获取浏览器的宽高
export function fGetWindowHeight(){
    return window.screen.height;
}




